import { Button } from "@mui/material";
import Button2 from "./button2";
import PropTypes from "prop-types";
import { Button3 } from "./button3";
import { TextBodyXLarge, TextDisplayLarge, TextLabelLarge } from "../common/typography";

const SectionTitle = ({ className = "", signup, scrollToTarget }) => {

  return(
    <div className="w-full bg-primary30 bg-[url('/gradient-14.svg')] bg-center bg-no-repeat bg-cover pt-[60px] px-[100px]  pb-[120px] flex flex-col items-center justify-center text-center">
      
      <div className="text-lightOnPrimary">
        <TextDisplayLarge color={'lightOnPrimary'} text={'Streamline Your Real Estate'}/>
        <br/><TextDisplayLarge color={'lightOnPrimary'} text={'Workflow With AI'}/>
      </div>
      <img
        className="self-stretch h-[8px] relative rounded-[4px] max-w-full overflow-hidden shrink-0"
        loading="lazy"
        alt=""
        src="/line-3.svg"
      />

      <div className="mt-5 text-lightPrimaryContainer">
        <TextBodyXLarge color={'lightPrimaryContainer'} text="ReBillion's advanced AI tools are designed to help real estate professionals manage" />
        <br/> <TextBodyXLarge color={'lightPrimaryContainer'} text="their tasks effortlessly and close deals faster." />
      </div>

      <div className="flex flex-row mt-10 gap-x-4">
          <button className="bg-lightPrimary text-lightOnPrimary w-[125px] h-[48px] rounded-md"  onClick={signup} >
              <TextLabelLarge color='lightOnPrimary' text={'Get started'} />
          </button>

          <button className="bg-lightPrimaryContainer w-[125px] h-[48px] rounded-md" onClick={scrollToTarget} >
              <TextLabelLarge color='lightOnPrimaryContainer' text={'Book demo'} />
          </button>
      </div>

    </div>
  );
  
};

SectionTitle.propTypes = {
  className: PropTypes.string,
};

export default SectionTitle;
