import { Button } from "@mui/material";
import HorizontalMiddleInset from "./horizontal-middle-inset";
import PropTypes from "prop-types";
import { TextLabelLarge } from "../common/typography";
import { Button3 } from "./button3";

const Header = ({ className = "", login, signup }) => {
  return (
    <header
      className={`self-stretch h-[80px] bg-primary30 overflow-hidden shrink-0 flex flex-row items-center justify-between py-[20px] px-[72px] box-border top-[0] z-[99] sticky max-w-full gap-[20px] text-left text-[24px] text-[#fff] font-sans mq1050:pl-[36px] mq1050:pr-[36px] mq1050:box-border ${className}`}
    >
      <div onClick={()=>window.open('https://rebillion.ai/')} className="flex flex-row items-center justify-start cursor-pointer">
        <div className="flex flex-col items-start justify-start">
          <div className="flex flex-row items-center justify-start">
            <img
              className="h-[35px] w-[35px] relative overflow-hidden shrink-0 object-cover min-h-[35px]"
              loading="lazy"
              alt=""
               src="/frame-1@2x.png"
            />
            <div className="flex flex-col items-start justify-start py-[0px] pl-[4px] pr-[0px]">
              <a className="[text-decoration:none] relative leading-[32px] font-semibold text-[inherit] inline-block min-w-[100px] whitespace-nowrap">
                ReBillion
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav className="m-[0px] flex flex-row items-center justify-start gap-[19.8px] max-w-full text-center text-[14px] text-lightPrimaryContainer font-sans mq750:hidden">
        <div onClick={()=>window.open('https://disclosures.rebillion.ai/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center">
            <a className="[text-decoration:none] relative leading-[20px] font-medium text-[inherit] whitespace-nowrap">
              Document summarizer
            </a>
          </div>
        </div>
        <div onClick={()=>window.open('https://agreements.rebillion.ai/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center">
            <a className="[text-decoration:none] relative leading-[20px] font-medium text-[inherit] inline-block min-w-[75px] whitespace-nowrap">
              Buyer Offer
            </a>
          </div>
        </div>
        <div onClick={()=>window.open('https://rebillion.ai/services/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center">
            <a className="[text-decoration:none] relative leading-[20px] font-medium text-[inherit] inline-block min-w-[59px]">
              Services
            </a>
          </div>
        </div>
        <div onClick={()=>window.open('https://rebillion.ai/blog/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center">
            <a className="[text-decoration:none] relative leading-[20px] font-medium text-[inherit] inline-block min-w-[30px]">
              Blog
            </a>
          </div>
        </div>
        <div onClick={()=>window.open('https://rebillion.ai/contact/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center">
            <a className="[text-decoration:none] w-[54px] relative leading-[20px] font-medium text-[inherit] flex items-center justify-center min-w-[54px]">
              Contact
            </a>
          </div>
        </div>
      </nav>
      <div className="self-stretch w-[176px] flex flex-row items-start justify-start gap-[16px]">
        <button onClick={login} className="cursor-pointer [border:none] p-[0px] bg-[transparent] rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
          <div className="flex flex-row items-center justify-center py-[10px] px-[12px]">
            <a className="[text-decoration:none] relative text-[14px] leading-[20px] font-medium font-sans text-[#fff] text-center inline-block min-w-[37px]">
              Login
            </a>
          </div>
        </button>
        <Button
          className="self-stretch flex-1 bg-bg-lightPrimary "
          disableElevation
          variant="contained"
          onClick={signup} 
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "14",
            background: "#498268",
            borderRadius: "6px",
            "&:hover": { background: "#498268" },
          }}
        >
          Sign up
        </Button>
      </div>
      <HorizontalMiddleInset />
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
