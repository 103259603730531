import { useMemo } from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const FrameComponent = ({
  className = "",
  rEBILLIONSUMMARIZER,
  simplifyDocumentSummarization,
  propHeight,
  propDisplay,
  designedToSaveYouTimeAndReduc,
  cardSpacing,
  signup
}) => {
  const simplifyDocumentSummarizatioStyle = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay,
    };
  }, [propHeight, propDisplay]);

  return (
    <div
      className={`self-stretch flex flex-row items-center justify-center gap-[40px] max-w-full text-left text-[14px] text-lighOnSurfaceVariant font-sans mq750:gap-[20px] mq1050:flex-wrap ${className}`}
    >
      <div className="w-[540px] flex flex-col items-start justify-start py-[20px] px-[0px] box-border gap-[20px] min-w-[540px] max-w-full mq750:min-w-full mq1050:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative leading-[20px] font-medium">
            {rEBILLIONSUMMARIZER}
          </div>
          <h2
            className="m-[0px] self-stretch relative text-[28px] leading-[36px] font-semibold font-[inherit] text-lightOnSurface mq450:text-[22px] mq450:leading-[29px]"
            style={simplifyDocumentSummarizatioStyle}
          >
            {simplifyDocumentSummarization}
          </h2>
          <div className="self-stretch relative text-[16px] leading-[24px]">
            {designedToSaveYouTimeAndReduc}
          </div>
        </div>
        <Button
          className="w-[125px] h-[40px]"
          disableElevation
          variant="outlined"
          sx={{
            textTransform: "none",
            color: "#498268",
            fontSize: "14",
            borderColor: "#737778",
            borderRadius: "6px",
            "&:hover": { borderColor: "#737778" },
            width: 125,
            height: 40,
          }}
          onClick={signup}
        >
          Get started
        </Button>
      </div>
      <img
        className="h-[372px] flex-1 relative rounded-[15px] max-w-full overflow-hidden min-w-[419px] mq750:min-w-full"
        loading="lazy"
        alt=""
        src={cardSpacing}
      />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  rEBILLIONSUMMARIZER: PropTypes.string,
  simplifyDocumentSummarization: PropTypes.string,
  designedToSaveYouTimeAndReduc: PropTypes.string,
  cardSpacing: PropTypes.string,

  /** Style props */
  propHeight: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default FrameComponent;
